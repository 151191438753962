import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  privacyLink: {
    marginBottom: 20,
    paddingLeft: 20,
  }
}));

const TermsOfUse = () => {
  const classes = useStyles();

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    script.type = 'text/javascript';
    script.id = 'otprivacy-notice-script';
    script.onload = () => {
      if (window.OneTrust && window.OneTrust.NoticeApi) {
        window.OneTrust.NoticeApi.Initialized.then(() => {
          window.OneTrust.NoticeApi.LoadNotices([
            'https://privacyportal-eu-cdn.onetrust.com/8394ad8c-2b46-4837-8771-cbc69779a644/privacy-notices/460d4e4d-c60e-4740-bd94-30b6dde2e870.json',
          ]);
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <Layout crumbs={getCrumbs('home/terms')}> 
      <Box className={classes.container}>
        <Box
          id='otnotice-460d4e4d-c60e-4740-bd94-30b6dde2e870'
          className='otnotice'
        ></Box>
        <Divider className={classes.divider} />
        <Typography className={classes.privacyLink}> 
          <a 
            href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/8394ad8c-2b46-4837-8771-cbc69779a644/31bea1f4-92c3-440b-be0e-468af4f4b1f3.html?Source=WasteNote" 
            target="_blank" 
            rel="noreferrer"
          >
            Terms of Use Web Form
          </a>
        </Typography>
      </Box>
    </Layout>
  );
};

export default TermsOfUse;
