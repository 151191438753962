/* global CONFIG */
import logger from "debug";
import { uniq } from "lodash";

// https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage
async function addToCache(urls) {
  const cache = await window.caches.open("image-cache");
  for (const url of urls) {
    window.fetch(url, { mode: "no-cors" }).then(function (response) {
      cache.put(url, response).then(logger("wastenot:info"));
    });
  }
}

// NB if images are already in workbox cache, those are used
const precacheContainerImages = async (profile) => {
  if (!profile) return;

  const containerImageUrls = profile.containers
    .map((container) =>
      container.images.map(
        (image) => `${container.image_base_url}/${image.url}`,
      ),
    )
    .flat();

  try {
    await addToCache(containerImageUrls);
  } catch (error) {
    logger("wastenot:error")("Error pre-caching container resources: ", error);
  }
};

const precacheAllImages = async (appSettings) => {
  // get all container images in all profiles
  const containerImageUrls = uniq(
    appSettings.profiles
      .map((profile) =>
        profile.containers
          .map((container) =>
            container.images.map(
              (image) => `${container.image_base_url}/${image.url}`,
            ),
          )
          .flat(),
      )
      .flat(),
  );

  const pages = [
    "/privacy",
    "/privacyPolicy",
    "/termsOfUse",
    "/help",
    "/kindOfWaste",
    "/whyTossed",
    "/destination",
    "/container",
    "/quantity",
    "/exactQuantity",
    "/typeOfWaste",
    "/summary",
  ];

  try {
    await addToCache(containerImageUrls);
    await addToCache(pages.map((path) => `${CONFIG.publicUrl}${path}`));
    await addToCache([`${CONFIG.publicUrl}/img/calculator.png`]);
  } catch (error) {
    logger("wastenot:error")("Error pre-caching container resources: ", error);
  }
};

export { precacheAllImages, precacheContainerImages };
