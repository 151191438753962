import { getI18n } from 'react-i18next';
import { get, has } from 'lodash';
import logger from 'debug';

// getCrumbs('home')
// getCrumbs('home/settings')
// getCrumbs('home/kindOfWaste')
// getCrumbs('home/kindOfWaste/whyTossed')

const t = key => getI18n().t(key, { ns: 'crumbs' });

// add crumbs for new pages here
// they are calculated on the fly from a simple path
const getAllCrumbs = () => ({
  home: {
    to: '/app',
    name: t('home'),
  },
  settings: {
    to: '/settings',
    name: t('settings'),
  },
  activation: {
    to: '/activation',
    name: t('activation'),
  },
  privacy: {
    to: '/privacy',
    name: t('privacy'),
  },
  privacyPolicy: {
    to: '/privacyPolicy',
    name: t('privacyPolicy'),
  },
  termsOfUse: {
    to: '/termsOfUse',
    name: t('termsOfUse'),
  },
  help: {
    to: '/help',
    name: t('help'),
  },
  kindOfWaste: {
    to: '/kindOfWaste',
    name: t('kindOfWaste'),
  },
  whyTossed: {
    to: '/whyTossed',
    name: t('whyTossed'),
  },
  destination: {
    to: '/destination',
    name: t('destination'),
  },
  container: {
    to: '/container',
    name: t('container'),
  },
  quantity: {
    to: '/quantity',
    name: t('quantity'),
  },
  exactQuantity: {
    to: '/exactQuantity',
    name: t('exactQuantity'),
  },
  typeOfWaste: {
    to: '/typeOfWaste',
    name: t('typeOfWaste'),
  },
  summary: {
    to: '/summary',
    name: t('summary'),
  },
});

const getCrumbs = crumbPath => crumbPath.split('/').map((key) => {
  const crumbs = getAllCrumbs();
  if (!has(crumbs, key)) {
    logger('wastenot:error')('Missing key for crumb (see crumbs.js and en.json) : ', key);
  }
  return has(crumbs, key) ? get(crumbs, key) : { to: '/app', name: `[${key}]` };
});

export default getCrumbs;
